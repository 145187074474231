import React from "react";
import { ReactComponent as GithubIcon } from "../assets/Github.svg";
import { ReactComponent as LinkedInIcon } from "../assets/LinkedIn.svg";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Fade } from "react-awesome-reveal";
import FooterSection from "./Footer";
const socialMedia = [
  {
    name: "Email",
    href: "mailto:jacksharris2909@gmail.com",
    icon: EnvelopeIcon,
  },
  //{ name: "Phone", href: "tel:+61401411820", icon: PhoneIcon },
  { name: "GitHub", href: "https://github.com/hungryjh", icon: GithubIcon },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/jack-harris-182347261",
    icon: LinkedInIcon,
  },
];

const ContactSection = () => {
  return (
    <div className="min-h-screen">
      <div className="bg-gray-100 text-center flex justify-center items-center h-screen w-full ">
        <div className="" id="contact">
          <div className="text-center mb-2">
            <h2 className="text-3xl font-bold ">Get in Touch!</h2>

            <p className="my-5 w-8/12 mx-auto">
              Whether its a job offer, a cool project idea, or any other
              exciting opportunity, please do get in touch with me with one of
              the methods below! (Or shoot me an email at
              jacksharris2909@gmail.com)
            </p>
          </div>

          <div className="flex justify-center space-x-6">
            <Fade cascade>
              {socialMedia.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className="text-gray-600 hover:text-gray-900"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <link.icon className="h-12 w-12 mx-auto" />
                  <span className="block text-sm">{link.name}</span>
                </a>
              ))}
            </Fade>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default ContactSection;
