import {
  AcademicCapIcon,
  CheckIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const StarIcon = CheckIcon;
const timelineItems = [
  {
    date: "2021 - 2023",
    title: "Bachelor of Information Technology - QUT",
    content:
      "Majored in Computer Science, with minors in Data Science and Artificial Intelligence. Graduated with a final GPA of 6.7, College of Excellence member, Dean’s List Award: (Semester 2, 2021, Semester 1, 2022, Semester 2, 2022). QUT Executive Dean’s Commendation for Academic Excellence (Semester 1 & 2023, Semester 2, 2023). Achieved all 7’s in my final year (8 subjects). ",
    icon: <AcademicCapIcon />,
  },
  {
    date: "2021 - 2022",
    title: "MyAffiliates.com ",
    content:
      "Worked casually for a software development company both remotely and on-site doing various technical jobs such as software upgrades and developing webpages. ",
    icon: <CodeBracketIcon />,
  },
  {
    date: "2022 - 2023",
    title: "QUT Capstone Project",
    content:
      "Worked in a team with the Brisbane-based company ‘Shorthand’ to produce a project involving the use of Artificial Intelligence, Data Science and User Experience Design to reformat content available online to a more user-friendly and engaging medium. Received 7’s for both semesters working on the project, and functionality based on our design was implemented into the website for their users.",
    icon: <AcademicCapIcon />,
  },
  {
    date: "2022 - 2024",
    title: "VR Motorsports",
    content:
      "I assisted the owner to develop software for the business, including creating modifications to a racing game to improve the user experience, extracting and processing data from a game, setting up and automating IOT devices, building & rebuilding hardware, diagnosing and correcting bugs and making reliability improvements across a range of different systems. ",
    icon: <CodeBracketIcon />,
  },
  {
    date: "January 2024 – March 2024",
    title: "Champion Web",
    content:
      "Employed full-time as part of a small team to help build and improve the frontend & backend of the system at the core of this startup using several languages and integrations centered around JavaScript. Additionally, I worked on automating both our own, and client’s systems with the use of AI. This involved identifying where AI-based automations may be beneficial and designing and implementing solutions for these areas.",
    icon: <CodeBracketIcon />,
  },
  {
    date: "June 2024 – Present",
    title: "Boeing Research & Technology",
    content:
      "Currently working full time as a software engineer with Boeing. Specializing in full stack development of cutting-edge simulation technologies and platforms.",
    icon: <CodeBracketIcon />,
  },
];

const Timeline = () => {
  return (
    <div className="bg-gray-100" id="timeline">
      <div className="max-w-4xl mx-auto px-4 py-5">
        {/* Header and Description */}
        <div className="text-center mb-2">
          <h2 className="text-3xl font-bold ">My Professional Journey</h2>

          <p className=" mt-2">
            Follow along with the key milestones in my career as a software
            developer, from my initial steps into the field to my current
            adventures in cutting-edge technology.
          </p>
        </div>
      </div>

      <VerticalTimeline>
        {timelineItems.map((timelineItem, index) => (
          <VerticalTimelineElement
            key={index}
            contentStyle={{ border: true }}
            className="vertical-timeline-element--work"
            date={timelineItem.date}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={timelineItem.icon}
          >
            <h2 className="vertical-timeline-element-title">
              {timelineItem.title}
            </h2>
            <h4 className="vertical-timeline-element-subtitle">
              {timelineItem.subtitle}
            </h4>
            <p>{timelineItem.content}</p>
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
      <div className="flex justify-center">
        <a
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10"
          href="#projects"
        >
          My Projects
        </a>
      </div>
    </div>
  );
};

export default Timeline;
