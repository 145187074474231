import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-gray-800 text-white p-2 w-full flex justify-between items-center">
      <h1 className="text-2xl font-bold">Jack Harris</h1>
      <ul className="flex justify-end space-x-5 items-center text-center">
        <li>
          <a href="#about" className="hover:text-gray-300">
            Skills
          </a>
        </li>
        <li>
          <a href="#timeline" className="hover:text-gray-300">
            Experience
          </a>
        </li>
        <li>
          <a href="#projects" className="hover:text-gray-300">
            Projects
          </a>
        </li>
        <li>
          <a href="#contact" className="hover:text-gray-300 ">
            Contact Me
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
