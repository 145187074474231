import "./App.css";
import React, { useEffect } from "react";
import Hero from "./components/Hero.jsx";
import Timeline from "./components/Timeline.jsx";
import Skills from "./components/Skills";

import ContactSection from "./components/Contact.jsx";
import { Fade } from "react-awesome-reveal";
import ProjectsSection from "./components/Projects.jsx";

function App() {
  // const [data, setData] = useState('');

  useEffect(() => {
    recordVisit();
  }, []);

  const recordVisit = async () => {
    let notifyString = "Personal site just got a visit!";
    let IP = null;

    try {
      const IPRequest = await fetch("https://api.ipify.org");
      IP = await IPRequest.text();

      const IPDetailsRequest = await fetch(`https://ipapi.co/${IP}/json/`);
      const IPDetails = await IPDetailsRequest.json();

      notifyString += ` Details:\nIP: ${IPDetails.ip}\nCity: ${IPDetails.city}\nRegion: ${IPDetails.region}\nCountry:${IPDetails.country}`;
    } catch (error) {
      notifyString +=
        " (Details Unavailable), Error:\n" + error + "\nIP: " + IP;
    }

    fetch(process.env.REACT_APP_DISCORD_API_URL, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        content: notifyString,
      }),
    });
  };

  return (
    <div className="App" id="app">
      <Fade duration={1200}>
        <Hero></Hero>
      </Fade>

      <Skills />

      <Fade>
        <Timeline />
        <ProjectsSection />
        <ContactSection />
      </Fade>
    </div>
  );
}
export default App;
