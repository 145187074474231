const FooterSection = () => {
  return (
    <div className="bg-gray-800 text-white p-4 grid grid-cols-1 md:grid-cols-3 md:flex md:justify-between md:px-10 text-center">
      <p>Made by Jack Harris 👋</p>
      <p>With React, JavaScript, TailwindCSS</p>
      <p>Jacksharris2909@gmail.com</p>
    </div>
  );
};

export default FooterSection;
