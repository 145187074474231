import React from "react";
import { Slide } from "react-awesome-reveal";

const blues = [
  "bg-blue-500/15",
  "bg-blue-500/20",
  "bg-blue-500/25",
  "bg-blue-500/30",
  "bg-blue-500/35",
  "bg-blue-500/40",
  "bg-blue-500/45",
  "bg-blue-500/50",
  "bg-blue-500/55",
];
const cardData = [
  {
    title: "Creating, Implementing & Researching AI Models",
    date: " 2021 - Present",
    paragraph:
      "I set up a second computer to train AI models I designed using TensorFlow, Keras and PyTorch. I Have experimented with different model architecture such as CNNs, ResNets, Transformers, Random Forests, Linear Classifiers, and many more. The goal of these models has varied from detecting different breeds of animals to stock market trends, determining where a person is looking using a webcam or creating a self-driving car. Data for these models has been pulled from a variety of places, including some clean datasets from hugging face, to manually extracting, processing, and cleaning data from google images.",
    frameworks:
      " TensorFlow, PyTorch, Keras, Python, NumPy, Scikit, Pandas, MATLAB, C#, SQLite, Azure, Google Colab",
  },
  {
    title: "Stewart Platform",
    date: "2023",
    paragraph:
      "Designed, 3D printed, assembled, and programmed a 6-axis Stewart motion platform. Used an Arduino to control the position of 6 servos, given a target transform and rotation which was sent via USB from a project written in C# on a PC. Enhanced my understanding of kinematics, 3D design & modelling and embedded devices, with the intention to extend this project with a camera, more joints, and the use of AI. ",
    frameworks:
      "Python, C#, C. Other Skills: Kinematics, 3D Design, Simple Electronics, Embedded Devices",
  },
  {
    title: "Game Development",
    date: "2022 - Present",
    paragraph:
      "Developed a variety of games with the Unity engine, python’s “pygame” package and Unreal Engine. Including: 2 simple mobile games, some space themed games using random numbers to produce an infinite environment, some village style games using an inventory system and pathfinding and some games designed to be played on a VR headset that make use of new SDKs such as shared spatial anchors. I found it was an entertaining way to explore a new concept & a great way to visualize how some algorithms & systems function",
    frameworks:
      "C#, C++, Python. Frameworks: Oculus, SteamVR, Android, Unity, Unreal Engine",
  },
  {
    title: "Virtual RC Car",
    date: "2022",
    paragraph:
      "Modified an RC car to be controlled via a USB steering wheel connected to a PC, with a camera on the car streaming a firstperson perspective to that computer. Experimented with several different devices to minimize latency and improve image quality, including a Raspberry Pi, Arduino, ESP32 and an FPV camera. I also modified the RC car including its servos, receiver, battery, motor, and 3D printed some replacement parts for it. Had the project working but could never get the latency to a point where it was unperceivable, which was the goal. ",
    frameworks:
      "ESP32, Raspberry Pi, FPV Camera, RC electronics. Languages & Frameworks: Python, C, HTML, video encoding, Linux ",
  },
  {
    title: "Bluetooth Car LEDs",
    date: "2021",
    paragraph:
      "Designed an app using Android studio to control a strip of LED lights via a Bluetooth connection to an ESP32. Designed a box to hold the electronics, which could be easily installed into a car for a custom undergrow effect. Created a list of lighting effects, including some that would sync to music using a small microphone wired into the ESP32. Successfully installed the project into my car and a friend’s car, had the intention of selling the kit, but never did due to massive existing competition in that area. ",
    frameworks:
      "Hardware: ESP32, Arduino, ARGB LEDs. Languages & Frameworks: Android Studio, Kotlin, C         ",
  },
  {
    title: "Game Server Hosting Website",
    date: "2021",
    paragraph:
      "Created a fully functional server hosting website, where users can pay for a game server to be hosted in the cloud. The website was hosted using python and Flask, with an SQL database serving as the backend. The servers were hosted on docker instances using Kubernetes to load balance across multiple machines. Payments were done via the stripe API, and JavaScript was used to bind everything together",
    frameworks:
      "Frontend: Bootstrap, CSS, HTML, Jinja, JavaScript. Backend: Python, Flask, Docker, Kubernetes, Ubuntu, AWS         ",
  },
  {
    title: "Functional Computer in A Video Game",
    date: "2021",
    paragraph:
      "Created a computer by designing & building simple logic gates in the game Minecraft. In the end it could calculate the first few iterations of the Fibonacci sequence. I designed every gate and module on my own as a challenge, using the knowledge of logic gates I learned from a course I was taking at the time. ",
    frameworks: "",
  },
  {
    title: "3D Renderer with Python",
    date: "2020",
    paragraph:
      "Taught myself the basics of 3d projection using Wikipedia to create a 3D rendering engine with python. Had it reading obj files, displaying objects in a 3D environment the player could move around in, and even a rudimentary lighting engine.",
    frameworks: " Python, NumPy, Multiprocessing ",
  },
  // Add more objects as needed
];

function ProjectsSection() {
  return (
    <div id="projects">
      <div className="md:mx-10">
        <div className="text-center my-10 w-10/12 mx-auto">
          <h2 className="text-3xl font-bold ">My Projects</h2>
          <p className=" mt-2">
            I have a passion for tech, and love to persue projects in all areas.
            Here are some of the most notible, as well as the technologies I
            used whilst making them.
          </p>
        </div>
        <div className="flex flex-wrap">
          {cardData.map((card, index) => (
            <Slide key={index} direction={index % 2 === 0 ? "left" : "right"}>
              <div
                className={`flex flex-col md:flex-row w-full items-center ${
                  index % 2 === 0 ? "" : "md:flex-row-reverse"
                }`}
              >
                <div className="md:w-3/4 p-4">
                  <div className={`p-6 shadow-lg rounded-lg ${blues[index]}`}>
                    <h2 className="text-2xl font-bold">{card.title}</h2>
                    <h3 className="text-xl">{card.date}</h3>
                    <p>{card.paragraph}</p>
                    <p>
                      <b>{card.frameworks}</b>
                    </p>
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </div>
        <div className="flex justify-center">
          <a
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10"
            href="#contact"
          >
            Contact Me
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSection;
