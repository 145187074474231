// myComponent.js
import React from 'react';
import { Fade } from 'react-awesome-reveal';




const languages = [
    { name: "Python", logo: "JS.svg" },
    { name: "JavaScript", logo: "Python.svg" },
    { name: "TypeScript", logo: "TypeScript.svg" },
    { name: "C++", logo: "CPlusPlus.svg" },
    { name: "C#", logo: "CSharp.svg" },
    { name: "Java", logo: "Java.svg" },
    { name: "Lua", logo: "Lua.svg" },
    { name: "MatLab", logo: "MatLab.svg" },
    { name: "PHP", logo: "PHP.svg" },
    { name: "R", logo: "R.svg" },
    { name: "HTML", logo: "HTML5.svg" },
    { name: "CSS", logo: "CSS.svg" },
    // Add more as needed
  ];

export default function Languages(props) {
    return (
        
        <div className="flex flex-wrap justify-center items-center gap-4 p-4">
            <Fade cascade duration={400}>
          {languages.map((language, index) => (
            <div key={index} className="w-24 h-24 bg-white rounded-full overflow-hidden flex items-center justify-center p-1 shadow-md">
              <img src={require(("../assets/" + language.logo))} alt={`${language.name} logo`} className="w-full h-full" />
            </div>
          ))}
          </Fade>
        </div>
       
      );
}
