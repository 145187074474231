import React from "react";
import Typewriter from "typewriter-effect";
import Navbar from "./navbar";

import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Hero = () => {
  const subtitles = [
    "Software Developer",
    "Data Scientist",
    "Machine Learning Engineer",
    "Full-Stack Engineer",
    "IT Graduate",
    "Tech Enthusiast",
  ];

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    //await console.log(container);
  }, []);

  return (
    <div className="h-screen">
      <Navbar />

      <div>
        <Particles
          id="particles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fullScreen: false,
            background: {
              image: " linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
            },
            particles: {
              number: { value: 10, density: { enable: true, value_area: 600 } },
              color: { value: "#ffffff" },
              shape: {
                type: "square",
                stroke: { width: 0, color: "#000000" },
                polygon: { nb_sides: 5 },
              },
              opacity: {
                value: 0.25,
                random: true,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 29,
                random: true,
                anim: { enable: false, speed: 2, size_min: 0.1, sync: false },
              },
              line_linked: {
                enable: false,
                distance: 300,
                color: "#ffffff",
                opacity: 0,
                width: 0,
              },
              move: {
                enable: true,
                speed: 0.5,
                direction: "top",
                straight: true,
                out_mode: "out",
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: { enable: true, mode: "repulse" },
                onclick: { enable: true, mode: "push" },
                resize: true,
              },
              modes: {
                grab: { distance: 800, line_linked: { opacity: 1 } },
                bubble: {
                  distance: 790,
                  size: 79,
                  duration: 2,
                  opacity: 0.8,
                  speed: 3,
                },
                repulse: { distance: 400, duration: 0.4 },
                push: { particles_nb: 1 },
                remove: { particles_nb: 2 },
              },
            },
            retina_detect: true,
          }}
        />
        <div className="h-screen flex justify-center items-center" id="hero">
          <div className="flex flex-col md:flex-row items-center justify-center p-10 bg-gray-900/50 rounded-lg max-w-5xl w-full mx-4 text-white">
            <div className="md:flex-1 md:mb-0 mb-6 ">
              <p>Hi, I'm</p>
              <h1 className="text-4xl font-bold mb-2">Jack Harris</h1>
              <Typewriter
                options={{
                  strings: subtitles,
                  autoStart: true,
                  loop: true,
                }}
              />

              <p className="my-8">
                Passionate about building complex systems involving all areas of
                IT. Specializing in data science, full-stack development,
                embedded systems and machine learning.
              </p>
              <a
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                href="#about"
              >
                About Me
              </a>
            </div>
            <div className="md:flex-1 flex justify-center">
              <img
                src={require("../assets/Jack Harris.png")}
                alt="Jack Harris"
                className="max-w-xs rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
